.logo-right {
  height: 200px;
  pointer-events: none;
  margin-bottom: 8px;
}

.icon {
  
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.icon {
  width: 50px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.icon-div {
  position: relative;
}

.skill {
  font-family: Raleway;
  font-weight: 400;
  line-height: 14px;
  font-size: 20px;
  text-align: center;
  color: rgb(37, 190, 195);
}

.short-bio {
  font-family: Raleway;
  font-weight: 300;
  text-align: justify;
}

.title {
  font-family: Raleway;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.16em;
  font-size: 24px;
  text-align: justify;
  color: rgb(37, 190, 195);
 
}

.item-title {
  font-family: Raleway;
  font-weight: 500;
  font-size: 20px;  
  margin-bottom: 0px;
}

.date {
  font-family: Raleway;
  font-weight: 500;
  text-align: right;
  margin-bottom: 8px;
}

.description {
  font-family: Raleway;
  font-weight: 300;
  text-align: justify;
  margin-bottom: 8px;
  line-height: 1.25;
}

.technologies {
  font-family: Raleway;
  font-weight: 500;
  line-height: 13px;
  text-align: justify;
  color: rgb(37, 190, 195);
  margin-bottom: 0px;
}

.bio-section-title {
  font-family: Raleway;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}

.bio-section-item {
  font-family: Raleway;
  font-weight: 300;
  line-height: 15px;
  text-align: center;
}